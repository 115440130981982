import { render, staticRenderFns } from "./BasisForm.vue?vue&type=template&id=bcd842d4&scoped=true"
import script from "./BasisForm.vue?vue&type=script&lang=js"
export * from "./BasisForm.vue?vue&type=script&lang=js"
import style0 from "./BasisForm.vue?vue&type=style&index=0&id=bcd842d4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcd842d4",
  null
  
)

export default component.exports