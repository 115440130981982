<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="题干"
      prop="questionStem"
      :rules="{
        required: true,
        message: '请填写题干',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.questionStem" placeholder="请输入" />
    </a-form-model-item>

    <a-form-model-item
      label="所属领域"
      prop="fieldId"
      :rules="
        form.id
          ? { required: false }
          : {
              required: true,
              message: '请选择所属领域',
              trigger: 'blur'
            }
      "
    >
      <h-cascader
        v-if="!form.id"
        v-model="form.fieldId"
        :options="fieldsOptions"
        :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
        placeholder="请选择"
      />
      <span v-else>{{ `${form.field.parent.name} > ${form.field.name}` }}</span>
    </a-form-model-item>

    <a-form-model-item
      label="所属等级"
      prop="level"
      :rules="
        form.id
          ? { required: false }
          : {
              required: true,
              message: '请选择所属等级',
              trigger: 'blur'
            }
      "
    >
      <h-select v-if="!form.id" v-model="form.level" allowClear placeholder="请选择">
        <a-select-option v-for="levelInfo in levelOptions" :key="levelInfo.value" :value="levelInfo.value">
          {{ levelInfo.label }}
        </a-select-option>
      </h-select>
      <span v-else>{{ form.level | SkillLevelText() }}</span>
    </a-form-model-item>

    <a-form-model-item label="启用状态">
      <a-radio-group v-model="form.status">
        <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
          {{ statusInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item
      label="实例"
      :prop="!form.instance && form.resourceList.length === 0 ? 'instance' : ''"
      :rules="
        !form.instance && form.resourceList.length === 0
          ? {
              required: true,
              message: '请输入实例或上传文件',
              trigger: 'blur'
            }
          : {}
      "
    >
      <a-row class="form-item-block" :gutter="[0, 4]">
        <a-col>
          <div style="position:relative">
            <a-input
              v-model="form.instance"
              placeholder="请输入内容"
              :maxLength="500"
              :autoSize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
              @change="() => $refs.form.clearValidate('instance')"
            />
            <div style="position:absolute;bottom:-5px;right:1px;">
              <span style="background:#fff">{{ form.instance.length }}/50</span>
            </div>
          </div>
        </a-col>

        <a-col>
          <resource-upload
            :accept="['jpg', 'png', 'jpeg', 'gif', 'mp4']"
            :limit="1024 * 1024 * 200"
            @success="data => form.resourceList.push(data)"
            @error="handleResourcesError"
            @change="handleResourcesChange"
          ></resource-upload>

          <div v-if="form.resourceList.length > 0" style="margin-top:8px">
            <a-row
              v-for="item in form.resourceList"
              :key="item.id"
              style="margin-bottom:8px;line-height:normal"
              type="flex"
              align="middle"
            >
              <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
                <a
                  v-if="item.type === 'VIDEO'"
                  @click="handlePreviewVideo(item.url)"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                >
                  <a-icon type="file" style="margin-right:8px" />{{ item.name }}
                </a>
                <a
                  v-else
                  :href="item.url"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                >
                  <a-icon type="file" style="margin-right:8px" />{{ item.name }}
                </a>
              </a-col>
              <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="removeResource(item)">
                <a-icon type="delete" class="error-color" />
              </a-col>
            </a-row>
          </div>

          <div class="disabled-color" style="line-height:1.5">
            提示：jpg/png/jpeg/gif/MP4格式文件，大小不超过200MB
          </div>
        </a-col>
      </a-row>

      <div v-if="resourceListErr" slot="extra" class="error-color">{{ resourceListErr }}</div>
    </a-form-model-item>

    <skill-modal
      ref="skillModal"
      :fieldId="form.fieldId[1] ? form.fieldId[1] : 0"
      :level="form.level || ''"
      :filterId="removedSkills"
      @submit="handleSkillChange"
    ></skill-modal>
    <a-form-model-item
      label="关联技能包"
      prop="skillList"
      :wrapperCol="{
        xs: {
          span: 24
        },
        sm: {
          span: 12
        }
      }"
    >
      <a-row v-if="form.skillList.length > 0" :gutter="[16, 16]">
        <a-col v-for="(skiilInfo, index) in form.skillList" :key="index" :span="24" style="position:relative">
          <a-row type="flex" align="middle" class="comp-bg" style="flex-wrap:nowrap;overflow:hidden;padding:12px">
            <a-col flex="0 1 80px" style="margin-right:24px">
              <a-avatar :size="80" shape="square" :src="skiilInfo.coverUrl"></a-avatar>
            </a-col>

            <a-col flex="0 1 100%" style="height:80px;overflow:hidden;line-height:1.5">
              <a-row type="flex" align="middle">
                <a-col class="desc">
                  <span class="heading-color">{{ skiilInfo.name || '-' }}</span>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="flex-wrap:nowrap;margin-top:16px;font-size:12px">
                <a-col class="desc">
                  阶段数:<span style="margin-left:8px">{{ skiilInfo.phaseCount || 0 }}</span>
                </a-col>
                <a-col>
                  <a-divider type="vertical"></a-divider>
                </a-col>
                <a-col class="desc">
                  创建人:<span style="margin-left:8px">{{ skiilInfo.creator || '-' }}</span>
                </a-col>
                <a-col>
                  <a-divider type="vertical"></a-divider>
                </a-col>
                <a-col class="desc">
                  添加时间:<span style="margin-left:8px">{{ skiilInfo.createTimestamp | moment('YYYY-MM-DD') }}</span>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top:8px;font-size:12px">
                <a-col flex="1" class="desc">
                  目标:<span style="margin-left:8px">{{ skiilInfo.goal }}</span>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-icon class="dynamic-delete-button" type="minus-circle-o" @click="removeSkill(skiilInfo)" />
        </a-col>
      </a-row>

      <a-button shape="round" @click="handleSkillSelect"><a-icon type="thunderbolt" theme="filled" />选择技能</a-button>
    </a-form-model-item>

    <a-form-model-item label="选项数量">
      <a-radio-group v-model="form.optionCount" @change="handleOptionsCount">
        <a-radio :value="1">1</a-radio>
        <a-radio :value="2">2</a-radio>
        <a-radio :value="4">4</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <skill-phase-modal ref="skillPhaseModal" :dataSource="skillPhaseOptions"></skill-phase-modal>
    <a-form-model-item
      label="选项"
      prop="options"
      :rules="{
        validator: checkOptions,
        trigger: 'change'
      }"
      :wrapperCol="{
        xs: {
          span: 24
        },
        sm: {
          span: 12
        }
      }"
      style="margin-bottom: 64px"
    >
      <a-row :gutter="[16, 16]">
        <a-col v-for="(optionInfo, index) in form.options" :key="index" :span="24">
          <a-row class="comp-bg">
            <a-col>
              <a-row type="flex" align="middle" style="flex-wrap:nowrap;overflow:hidden;padding:12px;line-height:1.5">
                <a-col flex="0 0 50px" style="margin-right:8px;white-space:nowrap;text-align:right">
                  选项{{ convertToChineseNumber(index + 1) }} :
                </a-col>

                <a-col flex="1" style="margin-right:8px">
                  <a-input
                    v-model.trim="optionInfo.description"
                    :disabled="index === 0"
                    @change="() => $refs.form.clearValidate('options')"
                  ></a-input>
                </a-col>

                <a-col style="margin:0 8px">
                  <!-- <a-input-number
                    v-model="optionInfo.score"
                    :min="0"
                    :max="5"
                    :disabled="index === 0"
                    @change="() => $refs.form.clearValidate('options')"
                  ></a-input-number> -->
                  {{ optionInfo.score }}
                </a-col>
                <a-col>分</a-col>
              </a-row>
            </a-col>

            <a-col v-if="index > 0">
              <a-row type="flex" align="top" style="flex-wrap:nowrap;overflow:hidden;padding:12px;line-height:1.5">
                <a-col flex="0 0 50px" style="margin-right:8px;white-space:nowrap;text-align:right">阶段 :</a-col>

                <a-col>
                  <div>
                    <a style="padding:0 7px" @click="handleOptionsSkillPhaseSelect(optionInfo)">关联</a>
                  </div>

                  <a-tag
                    v-for="(skillPhaseInfo, sIndex) in optionInfo.skillPhaseList"
                    :key="sIndex"
                    style="background:none;"
                    @close="removeOptionsSkillPhase($event, { option: optionInfo, index: sIndex })"
                    closable
                  >
                    {{ skillPhaseInfo.name }}
                  </a-tag>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as commonApi from '@/api/common'
import { convertToChineseNumber, previewVideo } from '@/utils/util'
import { COMMON_STATUS, SKILL_LEVEL } from '@/store/dictionary'
import { ImageUpload, ResourceUpload } from '@/components/_heath'
import SkillModal from './SkillModal'
import SkillPhaseModal from './SkillPhaseModal'

export default {
  components: {
    ImageUpload,
    ResourceUpload,
    SkillModal,
    SkillPhaseModal
  },
  data() {
    return {
      form: {
        questionStem: '',
        fieldId: [],
        level: undefined,
        status: 'AVAILABLE',
        instance: '',
        resourceList: [],
        skillList: [],
        skillIds: [],
        optionCount: 1,
        options: [
          {
            id: undefined,
            description: '以上行为均不具备',
            score: 0,
            serialNumber: 0,
            skillPhaseList: [],
            skillPhaseIds: []
          },
          {
            id: undefined,
            description: '',
            score: 4,
            serialNumber: 1,
            skillPhaseList: [],
            skillPhaseIds: []
          }
        ]
      },
      removedSkills: [],
      fieldsOptions: [],
      statusOptions: COMMON_STATUS,
      levelOptions: SKILL_LEVEL,
      resourceListErr: ''
    }
  },
  computed: {
    checkedSkillPhaseIds() {
      return Array.from(
        this.form.options
          .map(v => {
            return v.skillPhaseList.map(s => s.id)
          })
          .flat()
      )
    },
    skillPhaseOptions() {
      return this.form.skillList
        .filter(v => {
          return v.phaseList.some(s => !this.checkedSkillPhaseIds.includes(s.id))
        })
        .map(v => {
          return {
            ...v,
            phaseList: v.phaseList.filter(p => !this.checkedSkillPhaseIds.includes(p.id))
          }
        })
    }
  },
  activated() {
    this.initOptions()
  },
  methods: {
    convertToChineseNumber,
    checkOptions(rule, value, callback) {
      const hasEmpty = key => {
        const {
          form: { options }
        } = this
        return options.some(v => !v[key])
      }
      const hasSame = key => {
        const {
          form: { options }
        } = this
        const distinct = Array.from(new Set(options.map(v => v[key]))).length
        return distinct !== options.length
      }
      const phaseNotClear = () => {
        const { skillPhaseOptions } = this
        return skillPhaseOptions.length !== 0
      }

      if (hasEmpty('description')) {
        callback(new Error('请填写全部选项'))
      } else if (hasSame('description')) {
        callback(new Error('请去除重复选项'))
      } else if (phaseNotClear()) {
        callback(new Error('请将所有技能包阶段进行关联'))
      } else {
        callback()
      }
    },
    initData(form) {
      const {
        id,
        questionStem,
        field,
        level,
        status,
        instance,
        resourceList,
        skillList,
        skillIds,
        optionCount,
        optionList: options
      } = form

      const fieldId = [field.parent.id, field.id]

      this.form = {
        id,
        questionStem,
        field,
        fieldId,
        level,
        status,
        instance,
        resourceList,
        skillList,
        skillIds,
        optionCount,
        options
      }
    },
    initOptions() {
      commonApi.fieldsOptions().then(res => {
        this.fieldsOptions = res.data.filter(v => v.level === 'MAIN').filter(v => v.children.length > 0)
      })
    },
    formatForm() {
      const ret = cloneDeep(this.form)

      const { skillList, options } = ret
      let { fieldId } = ret
      delete ret.skillList

      const skillIds = skillList.map(v => v.id)
      options.forEach(v => {
        v.skillPhaseIds = v.skillPhaseList.map(s => s.id)
        delete v.skillPhaseList
      })
      fieldId = fieldId[1]

      return { ...ret, fieldId, skillIds, options }
    },
    removeResource(item) {
      const {
        form: { resourceList }
      } = this
      const index = resourceList.indexOf(item)
      if (index !== -1) {
        resourceList.splice(index, 1)
      }
    },
    handleResourcesError(err) {
      this.resourceListErr = err
    },
    handleResourcesChange() {
      this.resourceListErr = ''
      this.$refs.form.clearValidate('instance')
    },
    handleSkillSelect() {
      const {
        form: { fieldId, level, skillList },
        $refs: { skillModal },
        $message
      } = this

      if (fieldId.length === 0) {
        $message['warning']('请先选择所属领域')
        return
      } else if (!level) {
        $message['warning']('请先选择所属等级')
        return
      } else if (skillList.length === 4) {
        $message['warning']('至多关联4个技能包')
        return
      }

      skillModal.showModal()
    },
    handleSkillChange(skill) {
      console.log(skill)
      const {
        form: { skillList },
        $refs: { form }
      } = this

      if (skill) {
        if (!skillList.some(s => s.id === skill.id)) {
          skillList.push(skill)
          form.clearValidate('skillList')
        }
      } else {
        this.form.skillList = []
        this.form.options.forEach(v => (v.skillPhaseList = []))
      }
    },
    removeSkill(skill) {
      const index = this.form.skillList.indexOf(skill)

      if (index > -1) {
        const skillPhase2Remove = skill.phaseList.map(v => v.id)
        this.form.options.forEach(v => {
          v.skillPhaseList = v.skillPhaseList.filter(s => !skillPhase2Remove.includes(s.id))
        })
        this.form.skillList.splice(index, 1)
        this.removedSkills.push(skill.id)
      }
    },
    handleOptionsCount(val) {
      const { optionCount, options } = this.form
      const score = 4 / optionCount

      this.form.options = Array.from({ length: optionCount + 1 }, (v, i) =>
        options[i]
          ? { ...options[i], score: i === 0 ? 0 : score }
          : {
              id: undefined,
              description: '',
              score,
              serialNumber: i,
              skillPhaseList: [],
              skillPhaseIds: []
            }
      )
    },
    handleOptionsSkillPhaseSelect(option) {
      const {
        form: { skillList },
        skillPhaseOptions,
        $refs: { skillPhaseModal, form },
        $message
      } = this

      if (skillList.length === 0) {
        $message['warning']('请先关联技能包')
        return
      }
      if (skillPhaseOptions.length === 0) {
        $message['warning']('已关联全部技能包阶段')
        return
      }

      skillPhaseModal.showModal(e => {
        option.skillPhaseList = option.skillPhaseList.concat(e)
        form.clearValidate('options')
      })
    },
    removeOptionsSkillPhase(e, { option, index }) {
      e.preventDefault()
      option.skillPhaseList.splice(index, 1)
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-children {
  position: relative;
}
/deep/ .ant-input-number-input {
  text-align: center;
}
.form-item-block {
  padding: 16px;
  background: @comp-bg-color;
}
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dynamic-delete-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
  &:hover {
    color: #f5222d;
  }
}
</style>
