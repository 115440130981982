<template>
  <a-modal
    v-model="visible"
    title="关联技能包"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <a-row style="padding:24px">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="10" :md="12" :sm="24">
          <a-form-model-item label="搜索">
            <a-input-search
              v-model="name"
              allowClear
              placeholder="请输入技能包名称"
              style="width: 100%"
              @search="initData"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>

    <skill-table ref="table" @change="e => (selected = e)"></skill-table>
  </a-modal>
</template>

<script>
import * as skillApi from '@/api/skill'
import SkillTable from './SkillTable'

export default {
  components: {
    SkillTable
  },
  props: {
    fieldId: {
      type: Number,
      required: true
    },
    level: {
      type: String,
      required: true
    },
    filterId: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      visible: false,
      buttonLoading: false,
      name: '',
      selected: null
    }
  },
  methods: {
    showModal() {
      this.visible = true
      this.initData()
    },
    initData() {
      const { $nextTick, fieldId, level, name, filterId: skillIds, $refs } = this

      $nextTick(() => {
        $refs.table.initData({ fieldId, level, name, skillIds, isNotRelationSubject: true })
      })
    },
    handleOk() {
      const { $message, selected } = this

      if (selected) {
        this.buttonLoading = true
        skillApi
          .detail(selected.id)
          .then(res => {
            const { data } = res
            if (!data.phaseList || data.phaseList.length === 0) {
              $message['warning']('该技能包阶段信息异常，不可选择')
            } else {
              this.$emit('submit', data)
              this.selected = null
              this.visible = false
            }
          })
          .finally(() => (this.buttonLoading = false))
      } else {
        this.$emit('submit', null)
        this.visible = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
