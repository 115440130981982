<template>
  <div class="table-scroll-container">
    <a-card
      v-for="skill in list"
      :key="skill.id"
      :bordered="false"
      :title="skill.name"
      :bodyStyle="{ padding: '0 40px' }"
    >
      <div v-for="phase in skill.phaseList" :key="phase.id">
        <a-checkbox @change="select($event, phase)">
          <a-row :gutter="0" style="margin-bottom:16px;margin-top:-4px" class="table-row">
            <a-col>
              <a-row type="flex" align="middle">
                <a-col> </a-col>

                <a-col>
                  <span>{{ phase.name }}</span>
                </a-col>
              </a-row>
            </a-col>

            <a-col style="margin-top:8px">
              <p class="pre">{{ phase.goal }}</p>
            </a-col>
          </a-row>
        </a-checkbox>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selected: []
    }
  },
  methods: {
    select(e, record) {
      const { checked } = e.target

      if (checked) {
        this.selected.push(record)
      } else {
        const index = this.selected.indexOf(record)

        if (index > -1) {
          this.selected.splice(index, 1)
        }
      }

      this.$emit('change', this.selected)
    },
    initData(list) {
      this.list = list
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-row {
  cursor: pointer;
  &:hover {
    span,
    p {
      opacity: 0.75;
    }
  }
}
/deep/ .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
}
</style>
