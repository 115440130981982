<template>
  <a-modal
    v-model="visible"
    title="关联阶段"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', disabled: !selected } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <skill-phase-table ref="table" @change="e => (selected = e)"></skill-phase-table>
  </a-modal>
</template>

<script>
import SkillPhaseTable from './SkillPhaseTable'

export default {
  components: {
    SkillPhaseTable
  },
  props: {
    dataSource: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      visible: false,
      name: '',
      selected: null,
      callback: null
    }
  },
  methods: {
    showModal(callback) {
      this.visible = true
      this.callback = callback
      this.initData()
    },
    initData() {
      const { $nextTick, $refs, dataSource } = this

      $nextTick(() => {
        $refs.table.initData(dataSource)
      })
    },
    handleOk() {
      const { callback, selected } = this

      callback(selected)

      this.callback = null
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  height: calc(100vh - 200px - 110px);
  overflow-y: auto;
}
</style>
